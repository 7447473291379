<template>
  <v-card flat class="rounded-lg pa-2" height="100%" id="main-card">
    <v-card-title class="text-color-title heading font-weight-bold mb-5">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn depressed fab small color="transparent" @click="resetForm">
        <v-icon>mdi-restart</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <create-devotional-form :form.sync="form" :itemId="itemId">
      </create-devotional-form>
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="bg-secondary text-color-white text-capitalize rounded-lg"
        large
        :loading="loading"
        width="6rem"
        @click="save"
      >
        Save
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const devotionsRepository = RepositoryFactory.get("devotion");

const CreateDevotionalForm = () => import("../forms/CreateDevotionalForm.vue");

const defaultForm = {
  verse: null,
  verse_text: null,
  scheduled_at: null,
  verse_url: null,
  scripture_question: null,
  scripture_options: []
};

export default {
  components: {
    CreateDevotionalForm
  },

  data() {
    return {
      form: Object.assign({}, defaultForm),
      componentKey: 0,
      title: "Add Scheduled Devotion",
      loading: false,
      itemId: null
    };
  },

  methods: {
    populateForm({
      verse,
      verse_text,
      scheduled_at,
      verse_url,
      scripture_question,
      scripture_options
    }) {
      this.form = Object.assign(
        {},
        {
          verse,
          verse_text,
          scheduled_at: scheduled_at ? scheduled_at.split(" ")[0] : null,
          verse_url,
          scripture_question,
          scripture_options
        }
      );
    },

    async save() {
      const { scheduled_at } = this.form;
      const payload = {
        ...this.form,
        scheduled_at: scheduled_at ? `${scheduled_at} 00:00:00` : null
      };
      if (this.itemId) {
        await this.update(payload);
      } else {
        await this.create(payload);
      }
    },

    update(form) {
      this.loading = true;
      devotionsRepository
        .update(this.itemId, form)
        .then(() => {
          GLOBAL_EVENT_BUS.$emit("reloadItems_devotionals");
          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Updated.");
          this.resetForm();
          this.$nextTick(() => {
            this.$vuetify.goTo("#main-card");
          });
        })
        .catch(error => {
          let objectError = error.response.data.errors;
          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];
            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    create(form) {
      this.loading = true;
      devotionsRepository
        .create(form)
        .then(() => {
          GLOBAL_EVENT_BUS.$emit("reloadItems_devotionals");
          this.MIXINS_SHOW_SNACKBAR("success", "Successfully Created.");
          this.resetForm();
          this.$nextTick(() => {
            this.$vuetify.goTo("#main-card");
          });
        })
        .catch(error => {
          let objectError = error.response.data.errors;

          for (const [key] of Object.entries(objectError)) {
            let errorMessage = objectError[key][0];
            this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

            break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setupEventBus() {
      GLOBAL_EVENT_BUS.$off("showDetails_devotional");
      GLOBAL_EVENT_BUS.$on("showDetails_devotional", data => {
        this.title = "Edit Scheduled Devotion";
        this.itemId = data.id;
        this.populateForm(data);
      });
    },

    resetForm() {
      this.title = "Add Scheduled Devotion";
      this.form = Object.assign({}, defaultForm);
      this.itemId = null;
      GLOBAL_EVENT_BUS.$emit("resetSelected_devotional");
    }
  },
  mounted() {
    this.setupEventBus();
  }
};
</script>
